<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div>
      <div class="mainauto">
        <div class="mainTop">
          <div class="topTitle">联系我们</div>
          <div class="subTitle">Contact Us</div>
          <div class="cardBox">
            <div class="card">
              <div class="card_title">
                <img src="@/assets/aboutus/we_icon1.png" alt="" /><span
                  >电话热线</span
                >
              </div>
              <div class="card_title">13390762991</div>
              <div class="card_time">周一至周五9:00 - 18:00</div>
            </div>
            <div class="card">
              <div class="card_title">
                <img src="@/assets/aboutus/we_icon2.png" alt="" /><span
                  >联系邮箱</span
                >
              </div>
              <div class="card_title">admin@lingyuxx.com</div>
              <div class="card_time">周一至周五9:00 - 18:00</div>
            </div>
          </div>
        </div>
        <div class="mainBottom">
          <div class="bottom_bg">
            <img src="@/assets/aboutus/we_bottom_bg.png" alt="" />
          </div>

          <div class="bottom_card">
            <div class="cardFlex">
              <img src="@/assets/aboutus/we_icon3.png" alt="" />
              <div>
                <div class="bottom_title">公司名称</div>
                <div class="bottom_main">南京灵育信息科技有限责任公司</div>
              </div>
            </div>
            <div class="cardFlex">
              <img src="@/assets/aboutus/we_icon4.png" alt="" />
              <div>
                <div class="bottom_title">公司地址</div>
                <div class="bottom_main">江苏省南京市江北新区创芯汇3栋7楼707

</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about-we",
  data() {
    return {
      bglist: [{ id: 0, url: require("@/assets/aboutus/we_bg1.png") }],
    };
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.img {
  width: 100%;
  height: 100%;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;

  padding: 56px 0;
}
.topTitle {
  color: #333;
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
}
.subTitle {
  color: #999;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.cardBox {
  display: flex;
    display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 40px 0;
}
.card {
  width: 508px;
  height: 278px;
  background-color: #f4f4f6;
  padding: 26px 22px;
  line-height: 50px;
}
.card_title {
  font-size: 30px;
  color: #505050;
  font-weight: bold;
}
.card_title img {
  vertical-align: middle;
  margin-right: 20px;
  width: 38px;
  height: 38px;
}
.card_time {
  color: #999;
  font-size: 30px;
}
.bottom_bg {
  width: 100%;
  height: 480px;
}
.bottom_bg img {
  width: 100%;
  height: 100%;
}
.bottom_card {
  width: 100%;
  background-color: #f4f4f6;
  padding: 40px 20px 0;
  line-height: 50px;
}
.cardFlex {
  display: flex;
    display: -webkit-flex;
    padding-bottom: 40px;
}
.cardFlex img {
  width: 36px;
  height: 40px;
  margin-right: 24px;
}
.bottom_title {
  color: #323232;
  font-size: 28px;
  font-weight: bold;
}
.bottom_main {
  color: #333;
  font-size:24px;
}
</style>